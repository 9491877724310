import React, {useEffect, useState} from 'react';
import "./ChooseTeacher.scss";
import ChooseHeader from './ChooseHeader/ChooseHeader';
import MorInfo from './MorInfo/MorInfo';
import Container from "../../Components/Container/Container";
import MainContainer from "../../Components/MainContainer/MainContainer";
import Nav from "../Nav/Nav";
import {useDispatch, useSelector} from "react-redux";
import {chooseTeacherAsync} from './ChooseTeacherSlice';

export default function ChooseTeacher() {
    const [show, setShow] = useState(false);
    const [teacherPhoto, setTeacherPhoto] = useState();
    const [userId, setUserId] = useState();

    const data = useSelector((state) => state.chooseTeacher);
    const chooseTeacher = useSelector((state) => state.chooseTeacher.data);

    const token = localStorage.getItem('ACCESS_TOKEN');

    const dispatch = useDispatch();

    useEffect(() => {
        if (chooseTeacher && data.data.status === "success") {
            chooseTeacher.data.map((img) => {
                setTeacherPhoto(img.userId.photo);
            })
        }
    })

    const handleShow = (id) => {
        setShow(true)
        setUserId(id)
    }

    useEffect(() => {
        dispatch(chooseTeacherAsync(token))
    }, [show])

    return (
        <div className="choose_teacher">
            <Container>
                <MainContainer>
                    <Nav/>
                    <div className="choose_teacher_container">
                        <ChooseHeader/>
                        <div className="choose_teacher_component">
                            {chooseTeacher.data && chooseTeacher.data.map((teacherInfo, index) => (
                                <div key={teacherInfo._id} className="teacher_info">
                                    <div className="teacher_image">
                                        {teacherPhoto && <img src={`${process.env.REACT_APP_API_SHORT_URL}/images/${teacherInfo.userId.photo}`} alt="teacherPhoto"/>}
                                    </div>
                                    <div className="teacher_name">
                                        {teacherInfo.userId &&
                                            <h2>{teacherInfo.userId.firstName + " " + teacherInfo.userId.lastName}</h2>
                                        }
                                    </div>
                                    <div className="see_mor_info">
                                        <button onClick={() => handleShow(teacherInfo._id)}><p>See More</p></button>
                                    </div>
                                    {
                                        userId && userId === teacherInfo._id ?
                                            <MorInfo key={index} show={show} setShow={setShow} info={teacherInfo.userId}
                                                     techerInfoId={teacherInfo._id} teacherPhoto={teacherPhoto}/>
                                            : null
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </MainContainer>
            </Container>
        </div>
    )
}
