import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import "./NotificationTable.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    moderatorNotification,
    moderatorNotificationChange,
    moderatorNotificationChangeFInish
} from '../ModeratorNotificationSlice';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const columns = [
    {id: "text", label: "Notification", minWidth: 130, maxHeight: 10},
    {id: "name", label: "Sender Name", minWidth: 130, maxHeight: 10},
    {id: "sendtime", label: "Send Time", minWidth: 90, maxHeight: 10},
    {id: "moderatorname", label: "Moderator Name", minWidth: 120, maxHeight: 10},
    {id: "starttime", label: "Start time", minWidth: 90, maxHeight: 10},
    {id: "endtime", label: "End Time", minWidth: 90, maxHeight: 10},
    {id: "doit", label: "Do It", minWidth: 80, maxHeight: 10},
    {id: "message", label: "Message", minWidth: 80, maxHeight: 10},
];

export default function NotificationTable() {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [notofication, setNotofication] = useState([]);
    const [take, setTake] = useState(false);
    const [status, setStatus] = useState(false);
    const [tableData, setTableData] = useState(false);
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);

    const data = useSelector((state => state.moderatorNotification.data))

    const dispatch = useDispatch()

    const token = localStorage.getItem('ACCESS_TOKEN');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setTableData(!tableData)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const changeNotification = (notificationId) => {

        const value = {
            notificationId,
            token
        }
        setTake(!take)
        dispatch(moderatorNotificationChange(value))
    }

    const finishChanges = (notificationId) => {
        const value = {
            notificationId,
            token
        }
        setStatus(!status)
        dispatch(moderatorNotificationChangeFInish(value))
    }

    useEffect(() => {
        dispatch(moderatorNotification(token))
    }, [take, status])

    useEffect(() => {
        setNotofication(data.data)
    }, [data.data])

    return (
        <Paper sx={{width: "60"}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{maxHeight: 30}}>
                            {columns.map((column) => (
                                <>
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            top: 57,
                                            minWidth: column.minWidth,
                                            maxHeight: 30,
                                            fontSize: 12,
                                            minHeight: column.minHeight
                                        }}
                                    >
                                        <div className="moderatorteachertable_label">
                                            <p>
                                                {column.label}
                                            </p>
                                        </div>
                                    </TableCell>
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notofication && notofication
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                let creator = row.creatorId;
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            {
                                                if (column.id === "name" && creator) {
                                                    return (
                                                        <TableCell>
                                                            {creator.firstName + ' ' + creator.lastName}
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            {
                                                if (column.id === "sendtime") {
                                                    return (
                                                        <TableCell>
                                                            {new Date().toDateString(row.sendDate)}
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            {
                                                if (column.id === "starttime") {
                                                    return (
                                                        <TableCell>
                                                            {row.takerId
                                                                ? (new Date().toDateString(row.takerId.createDate)) : null
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            {
                                                if (column.id === "endtime") {
                                                    return (
                                                        <TableCell>
                                                            {row.finishDate
                                                                ? (new Date().toDateString(row.finishDate)) : null
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            {
                                                if (column.id === "moderatorname") {
                                                    return (
                                                        <TableCell>
                                                            {row.takerId ?
                                                                (row.takerId.firstName + ' ' + row.takerId.lastName)
                                                                :
                                                                <p>noting</p>
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            {
                                                if (column.id === "doit") {
                                                    return (
                                                        <TableCell>
                                                            {
                                                                !row.takerId ?
                                                                    (
                                                                        <div className="do_it">
                                                                            <button
                                                                                onClick={() => changeNotification(row._id)}>
                                                                                <p>Take</p></button>
                                                                        </div>)
                                                                    :
                                                                    (row.finishDate ?
                                                                        <div>Finished</div>
                                                                        :
                                                                        (<div className="do_it">
                                                                            <button
                                                                                onClick={() => finishChanges(row._id)}>
                                                                                <p>Finish</p></button>
                                                                        </div>))
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            {
                                                if (column.id === "message") {
                                                    return (
                                                        <TableCell>
                                                            <div className="message">
                                                                <button><p>Message</p></button>
                                                            </div>
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    <div className="moderatorstudentstable_unader">
                                                        <p>
                                                            {column.format && typeof value === "number"
                                                                ? column.format(value)
                                                                : value}
                                                        </p>
                                                    </div>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {notofication && <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                count={notofication.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Paper>
    )
}
