import React, {useEffect, useState} from 'react';
import WellDone from '../../Components/WellDone/WellDone';
import {useParams} from "react-router";
import axios from "axios";

function RegisterMailSent() {
    const [data, setData] = useState('')

    const {code} = useParams();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        axios.post(`${apiUrl}/student/finish-registration`, {code}).then(r => setData(r));
    }, [])

    return (
        <div>
            <WellDone text={'Your account has been successfully activated'} done={'Well done!'}/>
        </div>
    )
}

export default RegisterMailSent
