import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './Slider.scss';
import "swiper/css";
import "swiper/css/bundle";
import sound from '../../../Assets/sound.png'

export default function Slider({images}) {
    const [swiper, setSwiper] = useState({});
    const playAudio = (audio) => {
        new Audio(audio).play();
    }

    const slideNext = () => {
        swiper.slideNext();
    }

    const slidePrev = () => {
        swiper.slidePrev();
    }

    return (
        <div className='slider'>
            <ChevronLeftIcon onClick={slidePrev} className='next_prev'/>
            <Swiper
                slidesPerView={3}
                slidesPerGroup={1}
                spaceBetween={30}
                loop={true}
                loopFillGroupWithBlank={true}
                grabCursor={true}
                className="mySwiper"
                onInit={(ev) => {
                    setSwiper(ev)
                }}>
                {images && images.map((item,index)=>{
                    const voice = item.url.slice(0,-3)
                    return(
                        <SwiperSlide>
                            <div className="img">
                                <img
                                    src={`${process.env.REACT_APP_API_SHORT_URL}/homework/images/${item.url}`}
                                    alt=""/>
                                <div className="sound">
                                    <img src={sound} alt="" onClick={() => playAudio(`${process.env.REACT_APP_API_SHORT_URL}/homework/sounds/${voice}mp3`)}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    )

                })}

                {/*<SwiperSlide>*/}
                {/*    <div className="img">*/}
                {/*        <img src={aqlor} alt=""/>*/}
                {/*        <div className="sound">*/}
                {/*            <img src={sound} alt="" onClick={() => playAudio(aqlorMp3)}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*    <div className="img">*/}
                {/*        <img src={arev} alt=""/>*/}
                {/*        <div className="sound">*/}
                {/*            <img src={sound} alt="" onClick={() => playAudio(arevMp3)}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*    <div className="img">*/}
                {/*        <img src={aryuc} alt=""/>*/}
                {/*        <div className="sound">*/}
                {/*            <img src={sound} alt="" onClick={() => playAudio(aryucMp3)}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*    <div className="img">*/}
                {/*        <img src={ator} alt=""/>*/}
                {/*        <div className="sound">*/}
                {/*            <img src={sound} alt="" onClick={() => playAudio(atorMp3)}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</SwiperSlide>*/}
                {/*<SwiperSlide>*/}
                {/*    <div className="img">*/}
                {/*        <img src={avanak} alt=""/>*/}
                {/*        <div className="sound">*/}
                {/*            <img src={sound} alt="" onClick={() => playAudio(avanakMp3)}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</SwiperSlide>*/}
            </Swiper>
            <ChevronRightIcon onClick={slideNext} className='next_prev'/>
        </div>
    );
}
