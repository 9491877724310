import React, {useState} from 'react';
import './Letters.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {homeworkAssignAsync} from "./LettersSlice";

const Letters = ({age}) => {
    const homeworks = useSelector((state) => state.homework);
    console.log('homeworks', homeworks.assign)
    const [selected, setSelected] = useState([])
    const role = localStorage.getItem('ROLE');
    const token = localStorage.getItem('ACCESS_TOKEN');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = (id, index) => {
        setSelected([...selected, index])
        const value = {
            data: {
                "assignedStudentIds": [age],
                "homeworkIds": [id]
            },
            token: token,
        }
        dispatch(homeworkAssignAsync(value))
    }

    const handleOpen = (index) => {
        navigate(`/student/homeworks/letters`)
        localStorage.setItem('homeworkId', index);
    }

    return (
        <div className='letters'>
            {role === 'teacher' &&
                // data.map(((item, index) => {
                homeworks.data && homeworks.data.map(((item, index) => {
                    return (
                        <div key={index}
                             className={`img ${homeworks.assign.find((item => item.homeworkId.homeworkNumber === index + 1)) || selected.find((id => id === index + 1)) ? 'ok' : 'blur'}`}
                             onClick={() => handleClick(item._id, index + 1)}>
                            <img
                                src={`${process.env.REACT_APP_API_SHORT_URL}/homework/letters/${item.homeworkNumber}.png`}
                                alt=""/>
                            <p className='text'>Assign</p>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                                    stroke="#3DAFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 4.20001V9.00001L12.2 10.6" stroke="#3DAFFF" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    )
                }))
            }
            {role === 'student' && homeworks.studentHomework && homeworks.studentHomework.map(((item, index) => {
                return (
                    <div key={index}
                         className={`img ok2`}
                         onClick={() => handleOpen(index)}>
                        <img
                            src={`${process.env.REACT_APP_API_SHORT_URL}/homework/letters/${item.homeworkId.homeworkNumber}.png`}
                            alt=""/>
                        <p className='text2'>Perform</p>
                    </div>
                )
            }))
            }
        </div>
    );
};

export default Letters;
