import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    status: [],
    error: false,
    data: '',
}

export const changePassword = createAsyncThunk(
    'changePassword/fetchChangePassword',
    async (value) => {
        const response = await axios.patch(`${apiUrl}/user/update-password`, {
            ...value.pass
        }, {
            headers: {'Authorization': 'Bearer ' + value.token}
        });
        return response.data;
    }
)

export const profileDataSlice = createSlice({
    name: 'changePassword',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(changePassword.pending, (state) => {
                state.status = true
                state.error = false
                state.data = ''
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.status = false
                state.error = false
                state.data = action.payload.status
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.status = false
                state.error = true
                state.data = ''
            })
    },
})

export default profileDataSlice.reducer
