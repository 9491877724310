import React, {useEffect} from 'react'
import "./Header.scss";
import {useDispatch, useSelector} from "react-redux";
import {settingsAsync} from "../../Pages/Settings/SettingsSlice";

const Header = ({name}) => {
    const dispatch = useDispatch();

    const me = useSelector(((state) => state.settings))
    const token = localStorage.getItem('ACCESS_TOKEN')

    useEffect(() => {
        dispatch(settingsAsync(token))
    }, [])

    return (
        <div className="header">
            <h2>{name}</h2>
            <div className="header_img">
                {me.data.firstName && <h3>{me.data.firstName + " " + me.data.lastName}</h3>}
                <svg width="18" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
                        stroke="url(#paint0_linear_24_0)" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M11.73 20C11.5542 20.3031 11.3019 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64964 20.9965 9.30541 20.9044 9.00179 20.7295C8.69818 20.5547 8.44583 20.3031 8.27002 20"
                        stroke="url(#paint1_linear_24_0)" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <defs>
                        <linearGradient id="paint0_linear_24_0" x1="10" y1="1" x2="10" y2="16"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#78FAE9"/>
                            <stop offset="1" stopColor="#71C4FC"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_24_0" x1="10" y1="20" x2="10" y2="20.9965"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#78FAE9"/>
                            <stop offset="1" stopColor="#71C4FC"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div className="img">
                    {me.data.photo && <img src={`${process.env.REACT_APP_API_SHORT_URL}/images/${me.data.photo}`} alt='img'
                                           className="profile_picture"/>}
                </div>
            </div>
        </div>
    )
}

export default Header;
