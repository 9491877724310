import React from 'react';
import './ModeratorMessage.scss';
import ModeratorNav from '../ModeratorNav/ModeratorNav';
import Message from "../../../Components/Message/Message";
import io from "socket.io-client";

export default function ModeratorMessage() {
    const token = localStorage.getItem('ACCESS_TOKEN')
    const apiUrl = process.env.REACT_APP_API_SHORT_URL;

    const socket = io(apiUrl, {
        transportOptions: {
            polling: {
                extraHeaders: {"Authorization": 'Bearer ' + token}
            },
        },
    });

    const socket2 = io(`${apiUrl}/messenger`, {
        transportOptions: {
            polling: {
                extraHeaders: {"Authorization": 'Bearer ' + token}
            },
        },
    });
    return (
        <div className="moderator_message">
            <ModeratorNav/>
            <div className="moderator_message_container">
                <Message socket={socket} socket2={socket2}/>
            </div>
        </div>
    )
}
