import React, {useEffect} from 'react';
import MainContainer from "../../Components/MainContainer/MainContainer";
import Nav from "../Nav/Nav";
import Header from "../../Components/Header/Header";
import Container from "../../Components/Container/Container";
import HomeworksSelect from "./HomeworksSelect/HomeworksSelect";
import "./Homeworks.scss";
import Letters from "./Letters/Letters";
import {homeworkAsync, homeworkStudentAsync} from "./Letters/LettersSlice";
import {useDispatch, useSelector} from "react-redux";

function Homeworks() {
    const dispatch = useDispatch();
    const [age2, setAge2] = React.useState('letter');
    const [age, setAge] = React.useState(0);
    const token = localStorage.getItem('ACCESS_TOKEN');
    const role = localStorage.getItem('ROLE');

    const id = useSelector((state) => state.settings);

    useEffect(()=>{
        const value = {
            type:age2,
            token:token
        }
        const value1 = {
            id:id.data._id,
            token:token
        }
        console.log('value1',value1)
        if (role === 'student') {
            dispatch(homeworkStudentAsync(value1))
        }
        dispatch(homeworkAsync(value))
        // dispatch(homeworkStudentAsync(value))
    },[age2,id])

    return (
        <div className='homeworks'>
            <Container>
                <MainContainer>
                    <Nav/>
                    <div className="homeworks_page">
                        <Header name="Homeworks"/>
                        <div className="homeworks_container">
                            <HomeworksSelect age={age} setAge={setAge} setAge2={setAge2} age2={age2}/>
                            <Letters age={age} />
                        </div>
                    </div>
                </MainContainer>
            </Container>
        </div>
    );
}

export default Homeworks;
