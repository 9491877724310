// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/login_back.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".left_block {\n  width: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  border-bottom-left-radius: 50px;\n  border-top-left-radius: 50px; }\n  @media (max-width: 1100px) {\n    .left_block {\n      display: none; } }\n", "",{"version":3,"sources":["webpack://./src/Components/LeftBlock/LeftBlock.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,yDAAoD;EACpD,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,+BAA+B;EAC/B,4BAA4B,EAAA;EAE5B;IATF;MAUI,aAAa,EAAA,EAEhB","sourcesContent":[".left_block {\n  width: 50%;\n  background-image: url(\"../../Assets/login_back.png\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  border-bottom-left-radius: 50px;\n  border-top-left-radius: 50px;\n\n  @media(max-width: 1100px){\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
