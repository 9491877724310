import React from 'react';
import './LeftBlock.scss'

const LeftBlock = (props) => {
    return (
        <div className='left_block'>
        </div>
    );
};

export default LeftBlock;