import React from 'react';
import Message from '../../../Components/Message/Message';
import AdminNav from '../AdminNav/AdminNav';
import "./AdminMessage.scss"
import io from "socket.io-client";

export default function AdminMessage() {
    const token = localStorage.getItem('ACCESS_TOKEN')
    const apiUrl = process.env.REACT_APP_API_SHORT_URL;

    const socket = io(apiUrl, {
        transportOptions: {
            polling: {
                extraHeaders: {"Authorization": 'Bearer ' + token}
            },
        },
    });

    const socket2 = io(`${apiUrl}/messenger`, {
        transportOptions: {
            polling: {
                extraHeaders: {"Authorization": 'Bearer ' + token}
            },
        },
    });

    return (
        <div className="admin_message">
            <AdminNav/>
            <div className="admin_message_container">
                <div className="admin_message_component">
                    <Message socket={socket} socket2={socket2}/>
                </div>
            </div>
        </div>
    )
}
