import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
  status: false,
  error: false,
  errorMessage: '',
  data: {},
}

export const numberChange = createAsyncThunk(
  'number',
  async (value, {rejectWithValue}) => {
    try {
        const response = await axios.get(`${apiUrl}/user/request-number-change`, {
            headers: {'Authorization': 'Bearer ' + value}
        }).then();
        return response.data;
    } catch (e) {
        throw rejectWithValue(e.response.data.message);
    }
  }
)

export const teacherChange = createAsyncThunk(
  'teacher',
  async (value, {rejectWithValue}) => {
    try {
        const response = await axios.get(`${apiUrl}/user/request-teacher-change`, {
            headers: {'Authorization': 'Bearer ' + value}
        }).then();
        return response.data;
    } catch (e) {
        throw rejectWithValue(e.response.data.message);
    }
  }
)

export const requestCall = createAsyncThunk(
  'request-call',
  async (value, {rejectWithValue}) => {
    try {
        const response = await axios.get(`${apiUrl}/user/request-call`, {
            headers: {'Authorization': 'Bearer ' + value}
        }).then();
        return response.data;
    } catch (e) {
        throw rejectWithValue(e.response.data.message);
    }
  }
)

export const helpSlice = createSlice({
  name: 'change/',
  initialState,
  extraReducers: (builder) => {
      builder
          .addCase(numberChange.pending, (state) => {
              state.status = true
              state.error = false
              state.data = ''
          })
          .addCase(numberChange.fulfilled, (state, action) => {
              state.status = false
              state.error = false
              state.data = action.payload
          })
          .addCase(numberChange.rejected, (state, action) => {
              state.status = false
              state.error = true
              state.data = ''
          })
          .addCase(teacherChange.pending, (state) => {
            state.status = true
            state.error = false
            state.data = ''
        })
        .addCase(teacherChange.fulfilled, (state, action) => {
            state.status = false
            state.error = false
            state.data = action.payload
        })
        .addCase(teacherChange.rejected, (state, action) => {
            state.status = false
            state.error = true
            state.data = ''
        })
        .addCase(requestCall.pending, (state) => {
          state.status = true
          state.error = false
          state.data = ''
      })
      .addCase(requestCall.fulfilled, (state, action) => {
          state.status = false
          state.error = false
          state.data = action.payload
      })
      .addCase(requestCall.rejected, (state, action) => {
          state.status = false
          state.error = true
          state.data = ''
      })
  },
})

export default helpSlice.reducer