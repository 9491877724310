import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    status: false,
    error: false,
    errorMessage: '',
    data : [],
    studentHomework:[],
    assign:[]

}
export const homeworkAsync = createAsyncThunk(
    'homework/get',
    async (value, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${apiUrl}/assignment/homeworkByType?type=${value.type}`, {
                headers: {'Authorization': 'Bearer ' + value.token}
            }).then();
            return response.data;
        } catch (e) {
            throw rejectWithValue(e.response.data.message);
        }
    }
)
export const assignAsync = createAsyncThunk(
    'homework/assign',
    async (value, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${apiUrl}/assignment/student?id=${value.id}`, {
                headers: {'Authorization': 'Bearer ' + value.token}
            }).then();
            return response.data;
        } catch (e) {
            throw rejectWithValue(e.response.data.message);
        }
    }
)
export const homeworkStudentAsync = createAsyncThunk(
    'homework/student',
    async (value, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${apiUrl}/assignment/student?id=${value.id}`, {
                headers: {'Authorization': 'Bearer ' + value.token}
            }).then();
            return response.data;
        } catch (e) {
            throw rejectWithValue(e.response.data.message);
        }
    }
)
export const homeworkAssignAsync = createAsyncThunk(
    'homework/assign',
    async (value, {rejectWithValue}) => {
        try {
            const response = await axios.post(`${apiUrl}/assignment/`, {
                ...value.data
            }, {
                headers: {'Authorization': 'Bearer ' + value.token}
            }).then();
            return response.data;
        } catch (e) {
            throw rejectWithValue(e.response.data.message);
        }
    }
)
export const homeworkSlice = createSlice({
    name: 'homework',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(homeworkAsync.pending, (state) => {
                state.status = true
                state.error = false
                state.errorMessage = ''
            })
            .addCase(homeworkAsync.fulfilled, (state, action) => {
                state.status = false
                state.error = false
                state.data = action.payload.homework
            })
            .addCase(homeworkAsync.rejected, (state, action) => {
                state.status = false
                state.error = true
                state.errorMessage = action.payload
            })
            .addCase(homeworkStudentAsync.pending, (state) => {
                state.status = true
                state.error = false
                state.errorMessage = ''
            })
            .addCase(homeworkStudentAsync.fulfilled, (state, action) => {
                state.status = false
                state.error = false
                state.studentHomework = action.payload.data.assignments
            })
            .addCase(homeworkStudentAsync.rejected, (state, action) => {
                state.status = false
                state.error = true
                state.errorMessage = action.payload
            })
            .addCase(assignAsync.pending, (state) => {
                state.status = true
                state.error = false
                state.errorMessage = ''
            })
            .addCase(assignAsync.fulfilled, (state, action) => {
                state.status = false
                state.error = false
                state.assign = action.payload.data.assignments
            })
            .addCase(assignAsync.rejected, (state, action) => {
                state.status = false
                state.error = true
                state.errorMessage = action.payload
            })
    },
})

export default homeworkSlice.reducer