import React, {useEffect, useRef, useState} from 'react';
import './OneLetter.scss'
import MainContainer from "../../../Components/MainContainer/MainContainer";
import Nav from "../../Nav/Nav";
import Container from "../../../Components/Container/Container";
import {ReactSketchCanvas} from "react-sketch-canvas";
import Slider from "../Slider/Slider";
import {useSelector} from "react-redux";

const OneLetter = () => {
    const [id,setId] = useState()
    const homeworks = useSelector((state) => state.homework);
    const styles = {
        border: 'none',
    };

    useEffect(()=>{
      setId(JSON.parse(localStorage.getItem('homeworkId')))
    },[])

    const canvas = useRef();
    return (
        <div className='one_letter'>
            <Container>
                <MainContainer>
                    <Nav/>
                    <div className="one_letter_page">
                        <div className="letter_header">
                            <h2>Letter teaching</h2>
                        </div>
                        <div className="one_letter_container">
                            <div className="top_block">
                                <div className="letter">
                                    <p>{homeworks.studentHomework[id] && homeworks.studentHomework[id].homeworkId.words[0].value}</p>
                                </div>
                                <Slider images={homeworks.studentHomework[id] && homeworks.studentHomework[id].homeworkId.images}/>
                            </div>
                            <div className="bottom_block">
                                <div className="letter">
                                    <p>{homeworks.studentHomework[id] && homeworks.studentHomework[id].homeworkId.words[0].value}</p>
                                </div>
                                <ReactSketchCanvas
                                    ref={canvas}
                                    style={styles}
                                    width="70%"
                                    height="26.3vh"
                                    strokeWidth={10}
                                    strokeColor="white"
                                    backgroundImage={`${process.env.REACT_APP_API_SHORT_URL}/homework/dottedLetters/${id}.png`}
                                />
                            </div>
                            {/*<button*/}
                            {/*    onClick={() => {*/}
                            {/*        canvas.current.exportImage("png")*/}
                            {/*            .then(data => {*/}
                            {/*                console.log(data);*/}
                            {/*            })*/}
                            {/*            .catch(e => {*/}
                            {/*                console.log(e);*/}
                            {/*            });*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Get Image*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </MainContainer>
            </Container>
        </div>
    );
};

export default OneLetter;
